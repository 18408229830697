import { connect } from "react-redux";
import GridEventSkeleton from "../GridEventSkeleton/GridEventSkeleton";
import ListEventSkeleton from "../ListEventSkeleton/ListEventSkeleton";
import "./EventsSkeleton.css";

const EventsSkeleton = ({
    view
}) => {
    return (
          <div className={`news-sk-events-container ${view === "grid" ? "grid" : "list"}`}>
                    {view === "grid" ? 
                    <>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    <GridEventSkeleton/>
                    </>
                    : 
                    <>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    <ListEventSkeleton/>
                    </>          
                }
                </div>
    )
}

const mapStateToProps = (state) => ({
  view: state.appSource.appSource === "QR" ? state.events.QRView : state.appSource.appSource === "dine-in" ? state.events.dineInView : state.events.view,
});

export default connect(mapStateToProps, null)(EventsSkeleton);
