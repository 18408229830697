import './HomeSectionOneSkeleton.css';

const HomeSectionOneSkeleton = ({isQR}) => {
  return (
    <div className='sk-home-section-one-wrapper'>
      <div className='main-content white'>
        <div className='section-one-wrapper'>
          <div className='bar gmorning skeleton white'>
            {/* <div className='bar loyalty-btn skeleton white'></div> */}
          </div>
          <div className='gold-points-container skeleton'>
            <div className='bar gold skeleton white'></div>
            <div className='bar points skeleton white'></div>
            <div className='theme-switch skeleton white'></div>
            <div className='language-selection-icon skeleton white'></div>
          </div>
        </div>
        {!isQR && <>
        <div className='section-two-wrapper'>
          <div className='bar current-location skeleton white'></div>
          <div className='bar delivery-method-btns skeleton white'></div>
        </div>
        </>}
      </div>
      {!isQR && 
  <div className='bar min-charge'></div>}
    </div>
  );
};

export default HomeSectionOneSkeleton;



// import './HomeSectionOneSkeleton.css';

// const HomeSectionOneSkeleton = ({isQR}) => {
//   return (
//     <div className='sk-home-section-one-wrapper'>
//       <div className='bar resto-name skeleton white'></div>
//       <div className='main-content white'>
//         <div className='section-one-wrapper'>
//           <div className='bar gmorning skeleton white'></div>
//           <div className='gold-points-container skeleton'>
//             <div className='bar gold skeleton white'></div>
//             <div className='bar points skeleton white'></div>
//           </div>
//         </div>
//         {!isQR && <>
//         <div className='section-two-wrapper'>
//           <div className='bar current-location skeleton white'></div>
//           <div className='bar delivery-method-btns skeleton white'></div>
//         </div>
//         </>}
//       </div>
//       {!isQR && 
//   <div className='bar min-charge'></div>}
//     </div>
//   );
// };

// export default HomeSectionOneSkeleton;
