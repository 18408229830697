import "./GridEventSkeleton.css";

const GridEventSkeleton = () => {
    return (
        <div className="grid-event-sk-card">
            <div className="grid-event-sk-image skeleton light-gray"></div>
            <div className="grid-event-sk-texts">
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-text skeleton light-gray"></div>
                <div className="grid-event-sk-price-container">
                      <div className="grid-event-sk-text skeleton light-gray"></div>
                </div>
            </div>
        </div>
    )
}

export default GridEventSkeleton;
