import AddNewAddressLinkSkeleton from "../../DeliveryAddresses/NewAddressSkeleton/AddNewAddressLinkSkeleton";
import DeliveryAddressesSectionSkeleton from "../../DeliveryAddresses/DeliveryAddressSkeleton/DeliveryAddressesSectionSkeleton";
import "./DeliveryAddressesSkeleton.css";

const DeliveryAddressesSkeleton = () => {
  return (
    <div className="template-page-wrapper skeleton">
      <div className="template-header skeleton">
        <div className="template-header-title-skeleton skeleton white"></div>
        {/* <div className="template-header-skeleton-back skeleton white"></div> */}
      </div>
      <div className="template-main-container skeleton">
        <div className="template-main-content skeleton">
          <AddNewAddressLinkSkeleton />
          <DeliveryAddressesSectionSkeleton />
        </div>
      </div>
    </div>
  );
};

export default DeliveryAddressesSkeleton;
