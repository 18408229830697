import "./OrderCardSkeleton.css";

const OrderCardSkeleton = ({ orderType }) => {
  return (
    <div className={`order-card-skeleton ${orderType}`}>
      <div className="order-card-skeleton-order-number skeleton light-gray"/>
      <div className="order-card-skeleton-top-section">
        <div className="order-card-skeleton-top-left">
          <div className="order-card-skeleton-order-type skeleton light-gray"></div>
          <div className="order-card-skeleton-order-date skeleton light-gray"></div>
        </div>
        <div className="order-card-skeleton-order-status skeleton light-gray"></div>
      </div>
      <div className="order-card-skeleton-content-section">
        <div className="order-card-skeleton-content-one skeleton light-gray"></div>
        <div className="order-card-skeleton-content-two skeleton light-gray"></div>
        <div className="order-card-skeleton-content-three-container">
          <div className="order-card-skeleton-content-three skeleton light-gray"></div>
          <div className="order-card-skeleton-price skeleton light-gray"></div>
        </div>
      </div>
      <div className="order-card-skeleton-bottom-section">
        <div className="order-card-skeleton-bottom-left skeleton light-gray"></div>
        <div className="order-card-skeleton-bottom-right-container">
          <div className="order-card-skeleton-bottom-right-top skeleton light-gray"></div>
          <div className="order-card-skeleton-bottom-right-bottom skeleton light-gray"></div>
        </div>
      </div>
    </div>
  );
};

export default OrderCardSkeleton;
