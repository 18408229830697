import "./GallerySectionSkeleton.css";

const GallerySectionSkeleton = ({isAlone}) => {
    const photoElements = Array.from({ length: 21 }, (_, index) => (
    <div key={index + 1} className="skeleton white gallery-sk-image"></div>
  ));
    return (
        <div className={`gallery-sk-gallery-wrapper ${isAlone ? "add-padding" : "padding-top"}`}>
        <div className="gallery-sk-albums-titles">
          <div className="gallery-sk-album-one skeleton white"></div>
          <div className="gallery-sk-album-two skeleton white"></div>
          <div className="gallery-sk-album-three skeleton white"></div>
        </div>
        <div className="gallery-sk-photos-container three-columns-square">
          {photoElements}
        </div>
      </div>
    )
}

export default GallerySectionSkeleton;