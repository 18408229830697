import './PromotionCardSkeleton.css';

const PromotionCardSkeleton = () => {
    return (
        <div className='sk-promotion-card-container'>
            <div className='sk-promotion-card-left-section'>
                <div className='section-one skeleton light-gray'>
                </div>
                <div className='section-two skeleton light-gray'>
                </div>
                <div className='section-three skeleton light-gray'>
                </div>
                 <div className='section-four skeleton light-gray'>
                </div>
                 <div className='section-five skeleton light-gray'>
                </div>
            </div>
            <div className='sk-promotion-card-right-section'>
            </div>
        </div>
    )
}

export default PromotionCardSkeleton;
