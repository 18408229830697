import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { configurePersistor, configureStore } from "./redux/store/store";
import App from "./App";
import ThemeWrapper from "./theme";
import packageJson from '../package.json';

import localforage from "localforage";
import "./index.css";

// Redux setup
export const store = configureStore();
export const persistor = configurePersistor(store);
const wrapper = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <ThemeWrapper>
        <Router>
          <App />
        </Router>
      </ThemeWrapper>
    </PersistGate>
  </Provider>,
  wrapper
);

const checkForNewVersionAndClearCache = () => {

  const currentVersion = localStorage.getItem('appVersion');
  const newVersion = packageJson.version;

  if (currentVersion !== newVersion) {

    localStorage.clear();
    localforage.dropInstance().then(function () {
    }).catch(function (err) {
      console.error('Error deleting database:', err);
    });

    setTimeout(() => {
      window.location.reload();
    }, 0);

    localStorage.setItem('appVersion', newVersion);
  }
}

checkForNewVersionAndClearCache();

window.onload = () => {
  checkForNewVersionAndClearCache();
};

reportWebVitals();
