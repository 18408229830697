import { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader/Loader";

const fallback = <Loader/>
const UserRoute = ({ 
  element: Element, 
  token, 
}) => {
  const location = useLocation();

  return token ? (
    <Suspense fallback={fallback}>
      <Element />
    </Suspense>
  ) : (
    <Navigate to="/login" state={{ from: location.pathname }} />
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
});
export default connect(mapStateToProps)(UserRoute);
