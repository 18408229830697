import "./RecentReservationSkeleton.css";

const RecentReservationSkeleton = () => {
    return (
        <div className="recent-reservation-sk-wrapper">
            <div className="recent-reservation-sk-container">
            <div className="recent-reservation-sk-top-section">
                 <div className="recent-reservation-sk-title skeleton white"></div>
                <div className="recent-reservation-sk-edit skeleton white"></div>
            </div>
            </div>
            <div className="recent-reservation-sk-main skeleton white"></div>
            <div className="recent-reservation-sk-btn-section">
                <div className="recent-reservation-sk-remind-me-btn skeleton white"></div>
            </div>
        </div>
    )
}

export default RecentReservationSkeleton;