import { useEffect } from 'react';
import { connect } from "react-redux";
import { enableScroll, disableScroll } from '../../Common';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import { getSvg } from '../../utils/getSvg';
import './OptionsMessagePopup.css';

const OptionsMessagePopup = ({
  currentRestaurantModeIconsStyle,
  open, 
  setOpen, 
  messageTitle, 
  messageText, 
  optionOneText, 
  optionTwoText, 
  cancel, 
  cancelText, 
  optionOneCb, 
  optionTwoCb, 
  optionTwoStyle, 
  language,
  type="info"
 }) => {

  const handleCancel = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflowY = "hidden";
  //   } else {
  //     document.body.style.overflowY = "scroll";
  //   }
  //   return () => {
  //     document.body.style.overflowY = "auto";
  //   };
  // }, [open]);

  useEffect(() => {
    if(open) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [open])

  return (
    (open && 
      <div className={`options-message-popup-page-wrapper ${language === "arabic" ? "rtl" : ""}`}>
        <div className={`options-message-popup-container ${type}`}>
          {/* <div className='options-message-popup-title'>
            {messageTitle}
          </div>
          <div className='options-message-popup-text'>
            {messageText}
          </div> */}

          <div className='options-message-popup-texts-container'>
             <div className="options-message-popup-icon">
            {type === "info" ? (
              getSvg(`infoIcon${currentRestaurantModeIconsStyle}`, {color:"var(--main-color)"},
              {width:"20px", height: "20px"}
              )
            ) : type === "warning" ? (
              getSvg(`warningIcon${currentRestaurantModeIconsStyle}`, {color:"var(--waiting-color)"}, {width:"20px", height: "20px"})            ) 
              : type === "error" ? (
              <NotificationImportantOutlinedIcon className="confirm-popup-icon" />
            ) : null}
            </div>
          <div className='options-message-popup-texts'>
            <div className='options-message-popup-title'>{messageTitle}</div>
             <div className='options-message-popup-text'>
            {messageText}
          </div>
          </div> 
          </div>
         
          <div className='options-message-popup-btns'>
            <button className='options-message-popup-btn-option-one' onClick={()=>{optionOneCb()}}>
              {optionOneText}
            </button>
            {optionTwoText && (
              <button className={`${optionTwoStyle && optionTwoStyle === "normal" ? "options-message-popup-btn-option-two-normal" : "options-message-popup-btn-option-two"}`}onClick={()=>{optionTwoCb()}}>
              {optionTwoText}
            </button>
            )}
            {!((cancel && cancel==="no-cancel")) && (
            <button className='options-message-popup-btn-no' onClick={handleCancel}>
              {cancelText}
            </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  currentRestaurantModeIconsStyle: state.designs.currentRestaurantModeIconsStyle,

});

export default connect(mapStateToProps, null)(OptionsMessagePopup);

