import "./FavoriteCardSkeleton/FavoriteCardSkeleton";
import FavoritCardSkeleton from "./FavoriteCardSkeleton/FavoriteCardSkeleton";
import "./FavoritesSectionSkeleton.css";

const FavoritesSectionSkeleton = () => {
  return (
    <>
      <div className="favorites-section-skeleton-edit-link-container">
        <div className="favorites-section-skeleton-edit-link skeleton white"></div>
      </div>
      <div className="favorites-section-skeleton-favorites-container">
        <FavoritCardSkeleton />
        <FavoritCardSkeleton />
        <FavoritCardSkeleton />
        <FavoritCardSkeleton />
      </div>
    </>
  );
};

export default FavoritesSectionSkeleton;
