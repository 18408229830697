import { connect } from "react-redux";
import GOOGLE from "../../images/social_media/google-logo.png";

const SplashScreen = ( {
    splashScreen
}) => {
    return (
        <div
            style={{
                        width:"100%",
                        height: "100vh",
                        backgroundImage: `url("${splashScreen?.image}")`,
                        backgroundRepeat: (splashScreen?.type === "repeat") ? "repeat" : "no-repeat",
                        backgroundSize: splashScreen?.type === "repeat" ? "contain" : "cover",
                        backgroundColor: "var(--background-color)",
                        backgroundPosition: splashScreen?.type && splashScreen?.type === "cover" ? "center" : "initial"
            }}
        >
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    splashScreen: state.designs.splashScreen,
  }
}

export default connect(mapStateToProps, null)(SplashScreen);
