import "./DeliveryAddressCardSkeleton.css";

const DeliveryAddressCardSkeleton = () => {
  return (
    <div className="delivery-address-card-skeleton">
      <div className="delivery-address-card-skeleton-header">
        <div className="delivery-address-card-skeleton-header-left">
          <div className="label skeleton light-gray"></div>
          <div className="title skeleton light-gray"></div>
        </div>
        <div className="edit skeleton light-gray"></div>
      </div>
      <div className="delivery-address-card-skeleton-content">
        <div className="delivery-address-card-skeleton-content-line-one skeleton light-gray"></div>
        <div className="delivery-address-card-skeleton-content-line-two skeleton light-gray"></div>
        <div className="delivery-address-card-skeleton-content-line-three skeleton light-gray"></div>
      </div>
    </div>
  );
};

export default DeliveryAddressCardSkeleton;
