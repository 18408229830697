import "../DeliveryAddressCardSkeleton/DeliveryAddressCardSkeleton";
import DeliveryAddressCardSkeleton from "../DeliveryAddressCardSkeleton/DeliveryAddressCardSkeleton";
import "./DeliveryAddressesSectionSkeleton.css";

const DeliveryAddressesSectionSkeleton = () => {
  return (
    <div className="delivery-addresses-section-skeleton">
      <div className="delivery-addresses-section-skeleton-title skeleton white"></div>
      <DeliveryAddressCardSkeleton />
      <DeliveryAddressCardSkeleton />
      <DeliveryAddressCardSkeleton />
      <DeliveryAddressCardSkeleton />
      <DeliveryAddressCardSkeleton />
      <DeliveryAddressCardSkeleton />
    </div>
  );
};

export default DeliveryAddressesSectionSkeleton;
