import "./ListEventSkeleton.css";

const ListEventSkeleton = ({

}) => {
    return  (
        <div className="list-event-sk-wrapper">
            <div className="list-event-sk-top-section">
                <div className="list-event-sk-img skeleton light-gray"></div>
                <div className="list-event-sk-top-right-container">
                    <div className="list-event-sk-titles">
                    <div className="list-event-sk-text skeleton light-gray"></div>
                    <div className="list-event-sk-text skeleton light-gray"></div>
                    </div>
                    <div className="list-event-sk-desc">
                     <div className="list-event-sk-text skeleton light-gray"></div>
                    <div className="list-event-sk-text skeleton light-gray"></div>
                    <div className="list-event-sk-text skeleton light-gray"></div>
                     </div>
                </div>
            </div>
            <div className="list-event-sk-bottom-section">
                <div className="list-event-sk-text skeleton light-gray"></div>
                <div className="list-event-sk-text skeleton light-gray"></div>
            </div>
        </div>
    )
}

export default ListEventSkeleton;