import "./PreviousOrderSectionSkeleton.css";

const PreviousOrderSectionSkeleton = () => {
  return (
    <div className="previous-order-section-skeleton mainn-content">
      <div className="previous-order-section-skeleton-content">
        <div className="previous-order-section-skeleton-header">
          <div className="previous-order-section-skeleton-header-section-one skeleton light-gray"></div>
          <div className="previous-order-section-skeleton-header-section-two skeleton light-gray"></div>
          <div className="previous-order-section-skeleton-header-section-three skeleton light-gray"></div>
          <div className="previous-order-section-skeleton-header-section-four skeleton light-gray"></div>
        </div>
        <div className="previous-order-section-skeleton-items-container">
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name medium light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details">
              <div className="previous-order-section-skeleton-item-detail medium light-gray skeleton"></div>
            </div>
          </div>
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name large light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details ">
              <div className="previous-order-section-skeleton-item-detail small light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-detail large light-gray skeleton"></div>
            </div>
          </div>
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name medium light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details ">
              <div className="previous-order-section-skeleton-item-detail medium  light-gray skeleton"></div>
            </div>
          </div>
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name medium light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details ">
              <div className="previous-order-section-skeleton-item-detail large  light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-detail medium  light-gray skeleton"></div>
            </div>
          </div>
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name medium light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details ">
              <div className="previous-order-section-skeleton-item-detail large  light-gray skeleton"></div>
            </div>
          </div>
          <div className="previous-order-section-skeleton-item-container">
            <div className="previous-order-section-skeleton-item-top">
              <div className="previous-order-section-skeleton-item-name medium light-gray skeleton"></div>
              <div className="previous-order-section-skeleton-item-price light-gray skeleton"></div>
            </div>
            <div className="previous-order-section-skeleton-item-details ">
              <div className="previous-order-section-skeleton-item-detail medium  light-gray skeleton"></div>
            </div>
          </div>
        </div>
        <div className="previous-order-section-skeleton-bottom">
          <div className="previous-order-section-skeleton-bottom-row one">
            <div className="left skeleton light-gray"></div>
            <div className="right skeleton light-gray"></div>
          </div>
          <div className="previous-order-section-skeleton-bottom-row two">
            <div className="left skeleton light-gray"></div>
            <div className="right skeleton light-gray"></div>
          </div>
          <div className="previous-order-section-skeleton-bottom-row three">
            <div className="left skeleton light-gray"></div>
            <div className="right skeleton light-gray"></div>
          </div>
          <div className="previous-order-section-skeleton-bottom-row four">
            <div className="left skeleton light-gray"></div>
            <div className="right skeleton light-gray"></div>
          </div>
        </div>
        <div className="previous-order-section-skeleton-btns-container skeleton light-gray"></div>
      </div>
    </div>
  );
};

export default PreviousOrderSectionSkeleton;
