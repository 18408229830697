import axios from "axios";
import { getIncomingOrigin } from "../../utils/incomingOrigin";
import { CLOUD9_API, MESSAGES_API } from "../constants";
import { store } from '../../index';

const PrivateApiCall = axios.create({
  baseURL: CLOUD9_API,
});

const MessagesPrivateApiCall = axios.create({
  baseURL: MESSAGES_API,
});

PrivateApiCall.interceptors.request.use(
  (req) => {
    const state = store.getState();
    const token = state.auth.token;
    const reference = state.affiliateLinks.reference;
    req.headers.Authorization = `Bearer ${token}`;
    req.headers["incoming-origin"] = getIncomingOrigin();
    req.headers["ref"] = reference;
    return req;
  },
  (err) => {
    throw err;
  }
);

PrivateApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    throw err;
  }
);

const PublicApiCall = axios.create({
  baseURL: CLOUD9_API,
});

PublicApiCall.interceptors.request.use(
  (req) => {
    const state = store.getState();
    const reference = state.affiliateLinks.reference;
    req.headers["incoming-origin"] = getIncomingOrigin();
    req.headers["ref"] = reference;
    return req;
  },
  (err) => {
    throw err;
  }
);

PublicApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    throw err;
  }
);


MessagesPrivateApiCall.interceptors.request.use(
  (req) => {
    const state = store.getState();
    const token = state.auth.token;
    const reference = state.affiliateLinks.reference;
    req.headers.Authorization = `Bearer ${token}`;
    req.headers["incoming-origin"] = getIncomingOrigin();
    req.headers["ref"] = reference;
    return req;
  },
  (err) => {
    throw err;
  }
);

MessagesPrivateApiCall.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    throw err;
  }
);

export { PrivateApiCall, PublicApiCall, MessagesPrivateApiCall };