import ImageHeaderSkeleton from '../../ImageHeaderSkeleton/ImageHeaderSkeleton';
import HomeSectionOneSkeleton from '../../Home/HomeSectionOneSkeleton/HomeSectionOneSkeleton';
import OffersSkeleton from '../../Home/OffersSkeleton/OffersSkeleton';
import PopularItemsSkeleton from '../../Home/PopularItemsSkeleton/PopularItemsSkeleton';
import TopStickySectionSkeleton from '../../Home/TopStickySectionSkeleton/TopStickySectionSkeleton';
import ItemGroupSkeleton from '../../Home/ItemGroupSkeleton/ItemGroupSkeleton';
import BottomBarSkeleton from '../../BottomBarSkeleton/BottomBarSkeleton';
import './HomeSkeleton.css';

const HomeSkeleton = ({appSource}) => {
  return (
    <div className='sk-home-wrapper'>
      <ImageHeaderSkeleton />

      <div className='sk-home-content'>
        <HomeSectionOneSkeleton isQR={appSource === "QR" || false}/>
        <OffersSkeleton/>
        <PopularItemsSkeleton showTitle={true} />
        <TopStickySectionSkeleton showSearch={true} />
        <ItemGroupSkeleton />
      </div>
      <BottomBarSkeleton isQR={appSource === "QR" || false}/>
    </div>
  );
};

export default HomeSkeleton;
