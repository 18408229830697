import "./ReservationCardSkeleton.css";

const ReservationCardSkeleton = ({ reservationType = "previous" }) => {
  return (
    <div className={`reservation-card-skeleton ${reservationType}`}>
      <div className="reservation-card-skeleton-top-section">
        <div className="reservation-card-skeleton-date skeleton light-gray"></div>
        <div className="reservation-card-skeleton-status skeleton light-gray"></div>
      </div>
      <div className="reservation-card-skeleton-details">
        <div className="reservation-card-skeleton-detail-one skeleton light-gray"></div>
        <div className="reservation-card-skeleton-detail-two skeleton light-gray"></div>
      </div>
      <div className="reservation-card-skeleton-bottom-section">
        <div className="reservation-card-skeleton-bottom-left-btn skeleton light-gray"></div>
        <div className="reservation-card-skeleton-bottom-right-container">
          <div className="reservation-card-skeleton-branch skeleton light-gray"></div>
          <div className="reservation-card-skeleton-details-link skeleton light-gray"></div>
        </div>
      </div>
    </div>
  );
};

export default ReservationCardSkeleton;
