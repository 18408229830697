import "./AddressInfoSectionSkeleton.css";

const AddressInfoSectionSkeleton = () => {
  return (
    <div className="address-info-section-skeleton-container">
      <div className="address-info-section-skeleton-map-section">
        <div className="address-info-section-skeleton-map-title-section">
          <div className="address-info-section-skeleton-map-title skeleton white"></div>
          <div className="address-info-section-skeleton-refine-link skeleton white"></div>
        </div>
        <div className="address-info-section-skeleton-map"></div>
      </div>
      <div className="address-info-section-skeleton-info-form">
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-input skeleton white"></div>
        <div className="address-info-section-skeleton-directions skeleton white"></div>
        <div className="address-info-section-skeleton-confirm-container">
          <div className="address-info-section-skeleton-confirm-btn skeleton white"></div>
        </div>
      </div>
    </div>
  );
};

export default AddressInfoSectionSkeleton;
