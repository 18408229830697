import './ListItemSkeleton.css';

const ListItemSkeleton = () => {
  return (
    <div className='sk-litem-container skeleton'>
      <div className='sk-litem-image skeleton dark-gray'></div>
      <div className='sk-litem-details'>
        <div className='sk-litem-title skeleton light-gray'></div>
        <div className='sk-litem-desc-line-one skeleton light-gray'></div>
        <div className='sk-litem-desc-line-two skeleton light-gray'></div>
        <div className='sk-litem-bottom-details skeleton light-gray'>
          <div className='sk-litem-icons skeleton light-gray'></div>
          <div className='sk-litem-price skeleton light-gray'></div>
        </div>
      </div>
    </div>
  );
};

export default ListItemSkeleton;
