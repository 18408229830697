import ImageHeaderSkeleton from "../../ImageHeaderSkeleton/ImageHeaderSkeleton";
import GallerySectionSkeleton from "../../Gallery/GallerySectionSkeleton/GallerySectionSkeleton";
import BottomBarSkeleton from "../../BottomBarSkeleton/BottomBarSkeleton";
import "./GallerySkeleton.css";

const GallerySkeleton = ({
  appSource,
}) => {

  return (
    <div className="gallery-sk-wrapper">
      <ImageHeaderSkeleton replace={true} />
      <GallerySectionSkeleton/>
      <BottomBarSkeleton
        isQR={appSource === "QR" || false}
        hideCart={true}
      />
    </div>
  );
}

export default GallerySkeleton;

