import PopularItemSkeleton from '../PopularItemSkeleton/PopularItemSkeleton';
import './PopularItemsSkeleton.css';

const PopularItemsSkeleton = ({ showTitle }) => {
  const items = [1, 2, 3, 4];
  return (
    <div className={`sk-popular-items-wrapper ${showTitle ? "alone" : ""}`}>
      {showTitle && <div className='title skeleton white'></div>}
      <div className='sk-items-wrapper'>
        <div
          className='
      pitems-container'
        >
          {items.map((item, index) => {
            return <PopularItemSkeleton key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default PopularItemsSkeleton;
