import ImageHeaderSkeleton from "../../ImageHeaderSkeleton/ImageHeaderSkeleton";
import BottomBarSkeleton from "../../BottomBarSkeleton/BottomBarSkeleton";
import RecentReservationSkeleton from "../../News/RecentReservation/RecentReservationSkeleton";
import EventsSkeleton from "../../News/EventsSkeleton/EventsSkeleton";
import "./NewsSkeleton.css";

const NewsSkeleton = ({
    appSource, 
    isReservation,
    view
}) => {
    return (
        <div className="news-sk-wrapper">
            <ImageHeaderSkeleton replace={true}/>
            {isReservation && <RecentReservationSkeleton/>}
            <div className={`news-sk-events-wrapper ${!isReservation ? "padding-top" : ""}`}>
                <div className="news-sk-events-top-section">
                    <div className="news-sk-events-title skeleton white"></div>
                    <div className="news-sk-events-views">
                        <div className="news-sk-events-view left skeleton white"></div>
                        <div className="news-sk-events-view right skeleton white"></div>
                    </div>
                </div>
                <div className="news-sk-branches-list-container">
                    <div className="news-sk-branches-list skeleton white"></div>
                </div>
                <EventsSkeleton view={view}/>
              
            </div>
            <BottomBarSkeleton 
              isQR={appSource === "QR" || false}
              hideCart={true}
            />
        </div>
    )
}

export default NewsSkeleton;