import "./FavoriteCardSkeleton.css";

const FavoriteCardSkeleton = () => {
  return (
    <div className="favorite-card-skeleton-container">
      <div className="favorite-card-skeleton-image skeleton dark-gray"></div>
      <div className="favorite-card-skeleton-data-section">
        <div className="favorite-card-skeleton-name skeleton light-gray"></div>
        <div className="favortie-card-skeleton-price skeleton light-gray"></div>
      </div>
    </div>
  );
};

export default FavoriteCardSkeleton;
