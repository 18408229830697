export const getDictionary = (translation, component) => {
  let dictionary = translation?.filter((row) => {
    return row?.page === component;
  })
  return dictionary;
}

export const getField = (dictionary, field, language) => {
  if (dictionary?.length === 0 || !dictionary[0]?.values || !dictionary[0]?.values[field]) {
    return "No translation";
  }

  const value = dictionary[0]?.values[field][language] ? dictionary[0]?.values[field][language] : dictionary[0]?.values[field]["english"] ? dictionary[0]?.values[field]["english"] : "No translation";

  return value;
}

export const translateNumber = (number, language) => {
  if (language !== "arabic") {
    return number;
  }

  const arabicNumerals = {
    '0': '٠',
    '1': '١',
    '2': '٢',
    '3': '٣',
    '4': '٤',
    '5': '٥',
    '6': '٦',
    '7': '٧',
    '8': '٨',
    '9': '٩',
  };

  const stringNumber = number.toString();
  const arabicNumber = Array.from(stringNumber).map(digit => arabicNumerals[digit] || digit).join('');
  return arabicNumber;
};

// function to check if all the keys of an object are empty or null or undefined
const checkObjectValues = (obj) => {
  return Object.keys(obj).every(key => {
    const value = obj[key];
    return value === '' || value === null || value === undefined;
  });
}

export const getDataFieldTranslation = (dataObject, language) => {
  if (!dataObject) {
    return "";
  }

  if (checkObjectValues(dataObject)) {
    return "";
  }
  //else
  return (dataObject[language] ? dataObject[language] : dataObject["english"] ? dataObject["english"] : "No translation")
}