import PreviousOrderSectionSkeleton from "../../Orders/PreviousOrderSection/PreviousOrderSectionSkeleton";
import "./PreviousOrderSkeleton.css";

const PreviousOrderSkeleton = () => {
  return (
    <div className="template-page-wrapper previous-order-skeleton-page-wrapper skeleton">
      <div className="template-header skeleton">
        <div className="template-header-title-skeleton skeleton white"></div>
      </div>
      <div className="template-main-container previous-order-main-container skeleton">
        <div className="template-main-content">
        <PreviousOrderSectionSkeleton />
        </div>
      </div>
    </div>
  );
};

export default PreviousOrderSkeleton;
