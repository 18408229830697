import React, { useEffect, Suspense, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { actions as RestaurantsActions } from "../redux/actions/RestaurantsActions";
import { actions as CartActions } from "../redux/actions/CartActions";
import { actions as AppSourceActions } from "../redux/actions/AppSourceActions";
import { actions as ProductsActions } from "../redux/actions/ProductsActions";
import { actions as DeliveryMethodActions } from "../redux/actions/DeliveryMethodActions";
import UserRoute from "./UserRoute";
import Loader from "../components/Loader/Loader";

const DineInRoute = ({
  element: Element,
  outlet,
  isUserRoute,
  elementFallback,
  appSource,
  dineInCart,
  clearCart,
  dineInSelectedRestaurant,
  dineInSelectedBranch,
  dineInSelectedTable,
  availableRestaurants,
  restaurants,
  getRestaurantsLoading,
  setDineInSelectedRestaurant,
  setDineInSelectedBranch,
  setDineInSelectedTable,
  setSelectedMenu,
  resetMenus,
  setAppSource,
  changeDeliveryMethod,

  from,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const fallback = <Loader />;

  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    // Use the reloadKey to force a re-render when needed
    setReloadKey((prevKey) => prevKey + 1);
  }, [location.pathname]);

  //
  useEffect(() => {
    // if (availableRestaurants?.length > 0) {
    if (!getRestaurantsLoading && reloadKey === 1) {
      if (restaurants) {
        if (restaurants?.length === 0) {
          window.location.href = "https://www.cloudix.ai";
          return;
        }
        // Extract the UUID from the URL pathname
        const pathSegments = location.pathname.split("/");
        const restoUuid = pathSegments[2];
        const branchUuid = pathSegments[3];
        const table = pathSegments[4];
        // Check if the selected restaurant is valid
        const restaurantExists = restaurants?.find((resto) => {
          return resto?.uuid === restoUuid;
        });

        let branchExists;

        if (restaurantExists) {
          branchExists = restaurantExists?.branches?.find((branch) => {
            return branch?.uuid === branchUuid && branch?.isActive;
          });
        }

        if (
          !restaurantExists ||
          !branchExists ||
          !table ||
          (table && table.match(/^[0-9]+$/) == null)
        ) {
          // setDineInSelectedRestaurant(null);
          // setDineInSelectedBranch(null);
          // setDineInSelectedTable(null);
          //show page 404 not found
          window.location.href = "https://www.cloudix.ai";
          // navigate("/error");
          return;
        }
        setAppSource("dine-in");
        changeDeliveryMethod("dineIn");

        if (
          !dineInSelectedRestaurant ||
          (dineInSelectedRestaurant && dineInSelectedRestaurant !== restoUuid) ||
          !dineInSelectedBranch ||
          (dineInSelectedBranch && dineInSelectedBranch !== branchUuid) ||
          !dineInSelectedTable ||
          (dineInSelectedTable && dineInSelectedTable !== table)
        ) {
          clearCart(appSource);
          setDineInSelectedRestaurant(restoUuid);
          setDineInSelectedBranch(branchUuid);
          setDineInSelectedTable(table);
          resetMenus(appSource);
          setSelectedMenu(null, appSource);
          return;
        }
      }
    }
  }, [
    location.pathname,
    dineInSelectedRestaurant,
    dineInSelectedBranch,
    dineInSelectedTable,
    availableRestaurants,
    restaurants,
    getRestaurantsLoading,
    dineInSelectedTable,
    navigate,
    reloadKey
  ]);

  if (isUserRoute) {
    return <UserRoute element={Element} />;
  } else {
    return (
      <Suspense fallback={elementFallback ? elementFallback : fallback}>
        <Element />
        {outlet && <Outlet />}
      </Suspense>
    );
  }
};

const mapStateToProps = (state) => ({
  appSource: state.appSource.appSource,
  dineInCart: state.cart.dineInCart,
  dineInSelectedRestaurant: state.restaurants.dineInSelectedRestaurant,
  dineInSelectedBranch: state.restaurants.dineInSelectedBranch,
  dineInSelectedTable: state.restaurants.dineInSelectedTable,
  availableRestaurants: state.restaurants.availableRestaurants,
  restaurants: state.restaurants.restaurants,
  getRestaurantsLoading: state.restaurants.getRestaurantsLoading
});

const mapDispatchToProps = (dispatch) => ({
  setDineInSelectedRestaurant: (value) => {
    dispatch(RestaurantsActions.setDineInSelectedRestaurant(value));
  },
  setDineInSelectedBranch: (value) => {
    dispatch(RestaurantsActions.setDineInSelectedBranch(value));
  },
  setDineInSelectedTable: (value) => {
    dispatch(RestaurantsActions.setDineInSelectedTable(value));
  },
  clearCart: (appSource) => {
    dispatch(CartActions.clearCart(appSource));
  },
  setAppSource: (value) => {
    dispatch(AppSourceActions.setAppSource(value));
  },
  setSelectedMenu: (menuId, appSource) =>
    dispatch(ProductsActions.setSelectedMenu(menuId, appSource)),
  resetMenus: (appSource) => dispatch(ProductsActions.resetMenus(appSource)),
  changeDeliveryMethod: (method) =>
    dispatch(DeliveryMethodActions.changeDeliveryMethod(method))
});

export default connect(mapStateToProps, mapDispatchToProps)(DineInRoute);
