import "./LocationSearchSkeleton.css";

const LocationSearchSkeleton = () => {
  return (
    <div className="location-search-skeleton-wrapper">
      <div className="location-search-skeleton-container">
        <div className="location-search-skeleton-top skeleton light-gray"></div>
        <div className="location-search-skeleton-middle">
          <div className="location-search-skeleton-navigator-icon skeleton light-gray"></div>
          <div className="location-search-skeleton-current-location-btn skeleton light-gray"></div>
        </div>
        <div className="location-search-skeleton-bottom skeleton light-gray"></div>
      </div>
    </div>
  );
};

export default LocationSearchSkeleton;
