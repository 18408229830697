import "./AddNewAddressLinkSkeleton.css";

const AddNewAddressLinkSkeleton = () => {
  return (
    <div className="new-address-skeleton-section">
      <div className="new-address-skeleton-title skeleton white"></div>
      <div className="new-address-skeleton-link"></div>
    </div>
  );
};

export default AddNewAddressLinkSkeleton;
