import ReservationCardSkeleton from "../ReservationCardSkeleton/ReservationCardSkeleton";
import "./ReservationsSectionSkeleton.css";

const ReservationsSectionSkeleton = ({ reservationType }) => {
  return (
    <div className="reservations-section-skeleton-container">
      <div className="reservations-skeleton-top-btns skeleton white"> </div>

      <div className="reservations-skeleton-reservations-container">
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
        <ReservationCardSkeleton reservationType={reservationType} />
      </div>
    </div>
  );
};

export default ReservationsSectionSkeleton;
