import ReservationsSectionSkeleton from "../../Reservations/ReservationsSectionSkeleton/ReservationsSectionSkeleton";
import "./ReservationsSkeleton.css";

const ReservationsSkeleton = () => {
  return (
    <div className="template-page-wrapper skeleton">
      <div className="template-header skeleton">
        <div className="template-header-title-skeleton skeleton white"></div>
      </div>
      <div className="template-main-container skeleton">
        <div className="template-main-content skeleton">
          <ReservationsSectionSkeleton />
        </div>
      </div>
    </div>
  );
};

export default ReservationsSkeleton;
