import { useEffect, Suspense, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { actions as RestaurantsActions } from "../redux/actions/RestaurantsActions";
import { actions as CartActions } from "../redux/actions/CartActions";
import { actions as ProductsActions } from "../redux/actions/ProductsActions";
import UserRoute from "./UserRoute";
import { getDictionary, getField } from "../utils/translation";
import CartOptionsPopup from "../components/CartOptionsPopup/CartOptionsPopup";
import { notify } from "../utils/notify";
import Loader from "../components/Loader/Loader";

const RestaurantRoute = ({
  element: Element,
  outlet,
  isUserRoute,
  elementFallback,
  appSource,
  translation,
  language,
  cart,
  clearCart,
  addCartDraft,
  selectedRestaurant,
  currentAddress,
  deliveryMethod,
  setSelectedRestaurant,
  availableRestaurants,
  restaurants,
  getRestaurantsLoading,
  setSelectedMenu,
  resetMenus,
  from }) => {
    const dictionary = getDictionary(translation, "general");

  const location = useLocation();
  const navigate = useNavigate();
  const [openCartOptions, setOpenCartOptions] = useState(false);
  const [newSelectedRestaurant, setNewSelectedRestaurant] = useState(null);

  const fallback = <Loader />

  useEffect(() => {
    //
    if (!getRestaurantsLoading) {
      if (restaurants) {
        if (restaurants?.length === 0) {
          window.location.href = "https://www.cloudix.ai";
          return;
        }
        // Extract the UUID from the URL pathname
        const pathSegments = location.pathname.split("/");
        const uuid = pathSegments[1];

        if (restaurants?.length > 1) {
          // Check if the selected restaurant is valid
          const restaurantExists = restaurants?.find((resto) => {
            return resto?.uuid === uuid;
          })
          if (!restaurantExists) {
            if (cart?.length > 0) {
              //if the uuid is the same of the selected restaurant, but the restaurant is no more available, notify the user and clear the cart
              if (selectedRestaurant && selectedRestaurant === uuid) {
                notify.error(getField(dictionary, "restaurant-not-available-cart-cleared"), language);
                clearCart(appSource);
                navigate('/select-restaurant');
                return;
              }
              //else, if cart is not empty, and the uuid in url does not refer to a valid available restaurant
              setNewSelectedRestaurant(null);
              setOpenCartOptions(true);
              return;
            } else {
              navigate("/select-restaurant");
              setSelectedRestaurant(null);
              return;
            }
          }
          if (restaurantExists && !selectedRestaurant) {
            setSelectedRestaurant(uuid);
            return;
          }

          if (restaurantExists && selectedRestaurant !== uuid) {
            //if cart is empty, proceed
            if (cart?.length === 0) {
              setSelectedRestaurant(uuid);
              return;
            }
            //else
            //handle notify user here about saving his cart as a draft, or just save it as a draft
            //here//
            addCartDraft(selectedRestaurant, currentAddress, deliveryMethod);
            clearCart(appSource);
            setSelectedMenu(null, appSource);
            resetMenus(appSource);
            setSelectedRestaurant(uuid);

            // clearCart(appSource); //this is temporal
            // setSelectedRestaurant(uuid);
          }
        } else if (restaurants?.length === 1) {
          const availableRestaurantUuid = restaurants[0]?.uuid;

          if (selectedRestaurant !== availableRestaurantUuid) {
            clearCart(appSource);
            setSelectedRestaurant(availableRestaurantUuid);
            navigate(`/${availableRestaurantUuid}`);
          }
        }

        //else, if no available restaurants
        else {
          clearCart(appSource);
          navigate("/select-restaurant");
          setSelectedRestaurant(null);
        }
      }
    }
  }, [location.pathname,
    selectedRestaurant,
    availableRestaurants,
    restaurants,
    getRestaurantsLoading,
    navigate
  ]);


  if (isUserRoute) {
    return (
      <>
        {/* <CartOptionsPopup
      open={openCartOptions}
      setOpen={setOpenCartOptions}
      newSelectedRestaurant={newSelectedRestaurant}
      to="/select-restaurant"
      cancel="no-cancel"
      /> */}
        <UserRoute element={Element} />
      </>
    )
  } else {
    return (
      <>
        {/* <CartOptionsPopup
      open={openCartOptions}
      setOpen={setOpenCartOptions}
      newSelectedRestaurant={newSelectedRestaurant}
      to="/select-restaurant"
      cancel="no-cancel"
      /> */}
        <Suspense fallback={elementFallback ? elementFallback : fallback}>
          <Element />
          {outlet && <Outlet />}
        </Suspense>
      </>
    )
  }
};


const mapStateToProps = (state) => ({
  appSource: state.appSource.appSource,
  translation: state.translation.translation,
  language: state.language.language,
  cart: state.cart.cart,
  selectedRestaurant: state.restaurants.selectedRestaurant,
  availableRestaurants: state.restaurants.availableRestaurants,
  restaurants: state.restaurants.restaurants,
  getRestaurantsLoading: state.restaurants.getRestaurantsLoading,
  currentAddress: state.addresses.currentAddress,
  deliveryMethod: state.deliveryMethod.deliveryMethod,
});


const mapDispatchToProps = (dispatch) => ({
  setSelectedRestaurant: (value) => {
    dispatch(RestaurantsActions.setSelectedRestaurant(value));
  },
  clearCart: (appSource) => {
    dispatch(CartActions.clearCart(appSource));
  },
  addCartDraft: (ruuid, currentAddress, deliveryMethod) => {
    dispatch(CartActions.addCartDraft(ruuid, currentAddress, deliveryMethod));
  },
  setSelectedMenu: (menuId, appSource) =>
    dispatch(ProductsActions.setSelectedMenu(menuId, appSource)),
  resetMenus: (appSource) => dispatch(ProductsActions.resetMenus(appSource)),
});


export default connect(mapStateToProps, mapDispatchToProps)(RestaurantRoute);