import './GridItemSkeleton.css';

const GridItemSkeleton = () => {
  return (
    <div className='sk-item-container skeleton'>
      <div className='sk-item-image skeleton dark-gray'></div>
      <div className='sk-item-title skeleton light-gray'></div>
      <div className='sk-item-price skeleton light-gray'></div>
    </div>
  );
};

export default GridItemSkeleton;
