import DiscountCardSkeleton from "./DiscountCardSkeleton/DiscountCardSkeleton";
import PromotionCardSkeleton from "./PromotionCardSkeleton/PromotionCardSkeleton";
import './OffersSkeleton.css';

const OffersSkeleton = ({ loadingAlone }) => {
    return (
     <div className={`sk-offers-section ${loadingAlone ? "loading-alone" : " "}`}>
        <div className="sk-offers-title skeleton white">
        </div>
        <div className="sk-offers-container">
            <DiscountCardSkeleton/>
            <PromotionCardSkeleton/>
            <DiscountCardSkeleton/>
        </div>
     </div>
    )
}

export default OffersSkeleton;