import './TopStickySectionSkeleton.css';

const TopStickySection = ({ animate, showSearch, isSticky }) => {
  return (
    <div className={`sk-top-sticky-container ${isSticky ? "bg-image" : ""}`}>
      {showSearch && (
        <div className='sk-search-wrapper'>
          <div className='sk-search-bar skeleton white'></div>
          <div className='sk-view-btns-container'>
            <div className='sk-view-btn skeleton white'></div>
            <div className='sk-view-btn skeleton white'></div>
          </div>
        </div>
      )}
      <div className='sk-groups-wrapper'>
        <div className='sk-menu-icon skeleton'></div>
        <div className='sk-groups-container'>
          <div className='sk-group large skeleton white'></div>
          <div className='sk-group small skeleton white'></div>
          <div className='sk-group medium skeleton white'></div>
          <div className='sk-group large skeleton white'></div>
          <div className='sk-group small skeleton white'></div>
          <div className='sk-group medium skeleton white'></div>
          <div className='sk-group large skeleton white'></div>
          <div className='sk-group small skeleton white'></div>
          <div className='sk-group medium skeleton white'></div>
        </div>
      </div>
    </div>
  );
};

export default TopStickySection;
