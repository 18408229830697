
import CartBarSkelton from './CartBarSkeleton/CartBarSkeleton';
import './BottomBarSkeleton.css';

const BottomBarSkeleton = ({ hideCart, isQR }) => {
  return (
    <div className='sk-bottom-bar'>
      {!hideCart && <CartBarSkelton/>}
      <div className='sk-bottom-links skeleton'>
        {' '}
        <div className='sk-circle-btn skeleton'></div>
        <div className='sk-media-circle'></div>
        <div className='sk-left-links'>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div>
          {!isQR && (<>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div></>)}
        </div>
        <div className='sk-right-links'>
          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
          </div>
          {!isQR && (<>

          <div className='sk-bar-link'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
            </div>
        </>     
          )}
           <div className='sk-bar-link sk-bar-link-cart'>
            <div className='sk-icon skeleton light-gray'></div>
            <div className='sk-link skeleton light-gray'></div>
            </div>
        </div>
        <div className='sk-bottom-bar-footer skeleton light-gray'></div>
      </div>
    </div>
  );
};

export default BottomBarSkeleton;
