import FavoritesSectionSkeleton from "../../FavoritesSectionSkeleton/FavoritesSectionSkeleton";
import "./FavoritesSkeleton.css";

const FavoritesSkeleton = () => {
  return (
    <div className="template-page-wrapper skeleton">
      <div className="template-header skeleton">
        <div className="template-header-title-skeleton skeleton white"></div>
      </div>
      <div className="template-main-container skeleton">
        <div className="template-main-content skeleton">
          <FavoritesSectionSkeleton />
        </div>
      </div>
    </div>
  );
};

export default FavoritesSkeleton;
