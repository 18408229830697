import Shimmer from '../../Shimmer/Shimmer';
import './PopularItemSkeleton.css';

const PopularItemSkeleton = () => {
  return (
    <div className='sk-popular-item skeleton white'>
      <div className='sk-popular-item-title light-gray skeleton'></div>
    </div>
  );
};

export default PopularItemSkeleton;
