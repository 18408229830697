import "../OrderCardSkeleton/OrderCardSkeleton";
import OrderCardSkeleton from "../OrderCardSkeleton/OrderCardSkeleton";
import "./OrdersSectionSkeleton.css";

const OrdersSectionSkeleton = ({ orderType = "current" }) => {
  return (
    <div className="orders-section-skeleton-container">
      <div className="orders-skeleton-top-btns skeleton white"> </div>

      <div className="orders-skeleton-orders-container">
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
        <OrderCardSkeleton orderType={orderType} />
      </div>
    </div>
  );
};

export default OrdersSectionSkeleton;
