import MapSkeleton from "../../NewAddress/MapSkeleton/MapSkeleton";
import LocationSearchSkeleton from "../../NewAddress/LocationSearchSkeleton/LocationSearchSkeleton";
import "./AddressLocationSkeleton.css";

const AddressLocationSkeleton = () => {
  return (
    <div className="template-page-wrapper skeleton">
      <div className="template-header skeleton">
        <div className="template-header-title-skeleton skeleton white"></div>
      </div>
      <div className="template-main-container address-location-skeleton-main-container skeleton">
        <MapSkeleton />
        <LocationSearchSkeleton />
      </div>
    </div>
  );
};

export default AddressLocationSkeleton;
