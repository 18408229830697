import HeaderTopNavSkeleton from '../HeaderTopNavSkeleton/HeaderTopNavSkeleton';
import './ImageHeaderSkeleton.css';

const ImageHeaderSkeleton = ({
  replace
}) => {
  return (
    <div className={`sk-image-header dark-gray ${replace ? "replace": ""}`}>
      <div className='sk-image-header-title-container'>
      <div className='sk-image-header-logo skeleton white'></div>
      <div className='sk-image-header-text-container'>
      <div className='sk-header-resto-name skeleton white'></div>
      <div className='sk-header-change-resto skeleton white'></div>
      </div>
      </div>
      <HeaderTopNavSkeleton />
    </div>
  );
};

export default ImageHeaderSkeleton;
