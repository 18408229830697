import { connect } from "react-redux";
import GridItemSkeleton from "../ItemSkeleton/GridItemSkeleton";
import ListItemSkeleton from "../ItemSkeleton/ListItemSkeleton";
import "./ItemGroupSkeleton.css";

const ItemGroupSkeleton = ({ view }) => {
  return (
    <div className="sk-item-group-wrapper">
      <div className="sk-item-group-title"></div>
      <div
        className={
          view === "grid"
            ? "sk-items-container grid"
            : "sk-items-container list"
        }
      >
        {[1, 2, 3, 4, 5, 6].map((itm, i) => {
          return view === "grid" ? (
            <GridItemSkeleton key={i} />
          ) : (
            <ListItemSkeleton key={i} />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  view: state.products.view,
});

export default connect(mapStateToProps, null)(ItemGroupSkeleton);
